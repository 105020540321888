import { Route } from "@angular/router";
import { AccessDeniedComponent } from "@codman/shared/feature-static-pages";
import { auth0GuardCanActivate, auth0GuardCanLoad } from "@codman/shared/util-authorization";

export const appRoutes: Route[] = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
    },
    {
        path: "dashboard",
        loadComponent: () => import("@codman/etl/dashboard").then(c => c.EtlDashboardComponent),
        canLoad: [auth0GuardCanLoad],
        canActivate: [auth0GuardCanActivate],
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
];
