import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

import { useTranslationNamespace } from "@logex/framework/lg-localization";

import { StaticPageBase } from "./../shared/static-page-base";

@Component({
    selector: "access-denied",
    templateUrl: "./../shared/static-page.html",
    styleUrls: ["./../shared/static-page.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [useTranslationNamespace("APP._Shared.AccessDenied")],
    encapsulation: ViewEncapsulation.None,
    standalone: false,
})
export class AccessDeniedComponent extends StaticPageBase {}
