import { inject, NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeNl from "@angular/common/locales/nl";
import localeZu from "@angular/common/locales/zu";
import { TranslateModule, TranslateCompiler } from "@ngx-translate/core";

import { LgConsoleConfiguration } from "@logex/framework/core";
import {
    LgLocalizationModule,
    ReferenceTranslateCompiler,
    useMessageFormatLocales,
    LG_LOCALIZATION_SETTINGS,
    LocalizationSourceCallback,
} from "@logex/framework/lg-localization";
import { UiCoreModule } from "@logex/framework/ui-core";
import {
    IAppSession,
    LgUserflowConfiguration,
    LgUserflowService,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    LG_USERFLOW_CONFIGURATION,
    LG_USERFLOW_SERVICE,
    LG_USER_INFO,
} from "@logex/framework/lg-application";
import {
    LgLayoutModule,
    LgLocalStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE,
} from "@logex/framework/lg-layout";
import { LgChartsModule } from "@logex/framework/lg-charts";

import { SharedLocalizationSettingsService } from "./shared-localization-settings.service";
import { SharedConfigService } from "./shared-config.service";
import { SharedAppUser } from "./shared-app-user";
import { RegistryTenant } from "@codman/shared/assets";

const dummySession: IAppSession = {
    clientId: 10003,
    client: {
        id: 123,
        code: "123",
        name: "Test",
    },
    scenarioId: 1,
};

const TENANTS_WITHOUT_DYNAMIC_LOCALIZATION: RegistryTenant[] = ["pice", "rivm", "idr"];
export const TENANTS_WITHOUT_PORTAL_TABLES: RegistryTenant[] = ["pice", "rivm", "dicawgz", "niv"];
export const TENANTS_WITHOUT_OVERVIEW_TABLES: RegistryTenant[] = [
    "swab",
    "dicawgz",
    "niv",
    "rivm",
    "pice",
];

registerLocaleData(localeEn);
registerLocaleData(localeNl);
registerLocaleData(localeZu);

const getLgLocalizationSources: LocalizationSourceCallback[] = [
    // local static localization
    (locale, overlay) => (overlay == null ? `localization/${locale}.json` : null),
    // dynamic localization stored on the backend
    (locale, overlay) => {
        if (overlay != null) return null;
        const configuration = inject(SharedConfigService).getConfiguration();
        if (configuration.tenant === undefined) return null;
        if (
            TENANTS_WITHOUT_DYNAMIC_LOCALIZATION.find(
                (tenant: RegistryTenant): boolean => configuration.tenant === tenant,
            )
        )
            return null;
        if (locale === "zu-ZA") return null;
        return `${configuration.localizationApiUrl}/localization/${locale}`;
    },
];

@NgModule({
    imports: [
        UiCoreModule,
        LgLayoutModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization",
            getSourceUrls: getLgLocalizationSources,
        }),
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler },
        }),
        LgChartsModule.forRoot({ useNewColorPalette: true, useNewLabels: true }),
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useValue: new LgConsoleConfiguration(),
        },
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB", "zu-ZA"]),
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: SharedLocalizationSettingsService,
        },
        {
            provide: LG_APP_CONFIGURATION,
            useFactory: (configService: SharedConfigService) => configService.getAppConfiguration(),
            deps: [SharedConfigService],
        },
        {
            provide: LG_APP_SESSION,
            useValue: dummySession,
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService,
        },
        {
            provide: LG_USER_INFO,
            useValue: new SharedAppUser(),
        },
        {
            provide: LG_USERFLOW_SERVICE,
            useExisting: LgUserflowService,
        },
        {
            provide: LG_USERFLOW_CONFIGURATION,
            useClass: LgUserflowConfiguration,
        },
    ],
})
export class SharedUtilLogexFrameworkRootModule {}
