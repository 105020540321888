import { CodmanApplication } from "./codman-applications";

/**
 * Types
 */
export type AllowedLanguagesPerDashboard = Partial<
    Record<CodmanApplication, [string, ...string[]]>
>;

export interface LanguageConfiguration {
    /**
     * Default language that registry gets switched to.
     * If not specified, defaults to nl-NL
     */
    defaultLanguage?: string;
    /**
     * All available languages for specific dashboards.
     * If only one language is specified, it represents the default language for that dashboard.
     * If not specified, only default it used (for every dashboard).
     */
    dashboardLanguages?: AllowedLanguagesPerDashboard;
}

/**
 * Configuration
 */
export const availableLanguages: [string, ...string[]] = ["nl-NL", "en-GB"];

export const defaultLanguage = "nl-NL";

export const fallbackLanguage = "en";

export const allAvailableOnExplorationAndPortal: AllowedLanguagesPerDashboard = {
    exploration: availableLanguages,
    portal: availableLanguages,
};

export const englishOnlyRegistries = ["Icra"];

export const registryLocalizationConfiguration: Record<string, LanguageConfiguration> = {
    Daci: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dasa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dato: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dbir: {
        defaultLanguage: "en-GB",
        dashboardLanguages: {
            ...allAvailableOnExplorationAndPortal,
            descriptives: ["en-GB"],
            indicators: ["nl-NL"],
        },
    },
    Dcra: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DcraR: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dgda: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dgea: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Drce: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DgeaDrce: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dgoa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DgoaR: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dhba: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dhfa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dhna: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Diha: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DlcaL: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DlcaR: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DlcaS: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    DlcaSErats: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dma: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dmtr: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dnma: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dpa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dpaa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dpard: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dpca: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dppa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Draim: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Dsaa: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Duca: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Epsa: {
        defaultLanguage: "en-GB",
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Icra: {
        defaultLanguage: "en-GB",
        dashboardLanguages: {
            descriptives: ["en-GB"],
            indicators: ["en-GB"],
            portal: ["en-GB"],
        },
    },
    Nbca: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    NbcaR: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Testdaci: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Testdato: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Testdatomva: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
    Testdhba: {
        dashboardLanguages: allAvailableOnExplorationAndPortal,
    },
};
