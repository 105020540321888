import { FeatureFlagsAppConfig } from "./types";

export const exploration: FeatureFlagsAppConfig = {
    local: {
        all: {
            "filter-comparisons": true,
        },
        dica: {},
    },
    test: {
        all: {
            "filter-comparisons": true,
        },
        dica: {},
    },
    accept: {
        all: {
            "filter-comparisons": true,
        },
        dica: {},
    },
    staging: {
        all: {
            "filter-comparisons": true,
        },
        dica: {},
    },
    demo: {
        all: {
            "filter-comparisons": true,
        },
        dica: {},
    },
    prod: {
        all: {
            "filter-comparisons": true,
        },
        dica: {},
    },
};
