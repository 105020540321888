import { Environment, InsightsApplication, RegistryTenant } from "@codman/shared/assets";
import { Attributes } from "userflow.js";

import { Auth0Config } from "./auth0";
import { IColor } from "./color";
import { IMatomo } from "./matomo";
import { IUserflowConfiguration } from "./userflow";

export interface IEnvironmentSettingsLayout {
    sharedUiFooterShowDicaLogo: boolean;
    sharedUiFooterLocalizationId: string;
}

export interface IEnvironmentSettingsTheme {
    provider: IColor;
    benchmark: IColor;
    benchmarkConnect: IColor;
    sharedOrganization: IColor;
}

export interface IEnvironmentColorTheme {
    provider: string;
    benchmark: string;
    benchmarkConnect: string;
    benchmarkProviderType: string;
    sharedOrganization: string;
}

export interface EnvironmentSettings {
    application: InsightsApplication;
    applicationInstance: string;
    environment: Environment;
    apiPort?: number;
    tenant?: RegistryTenant;
    domain: string;
    version: string;
    auth0: Auth0Config;
    authApiUrl: string;
    matomo: IMatomo;
    userflow?: IUserflowConfiguration;
    theme?: IEnvironmentSettingsTheme;
    layout?: IEnvironmentSettingsLayout;
}

export interface UserflowUserAttributes extends Attributes {
    dotEnv: string;
    tenant?: RegistryTenant;
    tenantAbbreviation?: string;
}
