export interface IMatomo {
    siteId: number;
    organizationDimensionId: number;
    positionDimensionId: number;
    registryDimensionId: number;
    subsetDimensionId?: number;
    versionDimensionId?: number;
    pageDimensionId: number;
    tenantDimensionId: number;
    organizationNameDimensionId: number;

    // patients
    registry: number;
    provider: number;
    providerRegistry: number;
    registryProvider: number;
    position: number;
}
