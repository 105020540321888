import { InsightsApplication } from "@codman/shared/assets";

export const APP_INSTANCE_LOOKUP: { [app in InsightsApplication]: string } = {
    descriptives: "ApplicationInstance.CodmanDescriptives.NL",
    exploration: "ApplicationInstance.CodmanExploration.NL",
    indicators: "ApplicationInstance.CodmanIndicators.NL",
    patients: "ApplicationInstance.CodmanPatients.NL",
    overview: "ApplicationInstance.CodmanOverview.NL",
    portal: "",
    // non-Codman
    "data-request": "ApplicationInstance.DataRequest.NL",
};

export const APP_CA_INSTANCE_LOOKUP: { [app in InsightsApplication]: string } = {
    descriptives: "ApplicationInstance.CodmanDescriptives.CA",
    exploration: "ApplicationInstance.CodmanExploration.CA",
    indicators: "ApplicationInstance.CodmanIndicators.CA",
    patients: "ApplicationInstance.CodmanPatients.CA",
    overview: "ApplicationInstance.CodmanOverview.CA",
    portal: "",
    // non-Codman
    "data-request": "ApplicationInstance.DataRequest.NL",
};
