import { Environment } from "./../environment";
import { InsightsApplication } from "./../codman-applications";
import { RegistryTenant } from "./../registry-tenants";

type FeatureFlagsApp = InsightsApplication | "shared";

export type Feature = "filter-comparisons" | "highlights";

export type FeatureFlagsConfig = Record<FeatureFlagsApp, FeatureFlagsAppConfig>;

export type FeatureFlagsAppConfig = Record<Environment, FeatureFlagsEnvironmentConfig>;

export type FeatureFlagsEnvironmentConfig = Partial<
    Record<RegistryTenant | "all", FeatureFlagsFeatureConfig>
>;

export type FeatureFlagsFeatureConfig = Partial<Record<Feature, boolean>>;
