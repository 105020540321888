interface IProductConfig {
    id: string;
    name?: string;
    nameLc?: string;
    appInstances: string[];
    getUrl: (urlConfig: UrlConfig) => string;
    shortDescription?: string;
    icon?: string;
    iconClass?: string;
}

interface UrlConfig {
    tenant?: string;
    dotEnv: string;
    dashEnv: string;
}

export const MRDM_PRODUCTS: IProductConfig[] = [
    {
        id: "codman",
        name: "Codman",
        appInstances: [
            "ApplicationInstance.CodmanIndicators.CA",
            "ApplicationInstance.CodmanDescriptives.CA",
            "ApplicationInstance.CodmanIndicators.NL",
            "ApplicationInstance.CodmanDescriptives.NL",
            "ApplicationInstance.CodmanExploration.NL",
            "ApplicationInstance.CodmanPatients.NL",
        ],
        getUrl: ({ tenant, dotEnv }: UrlConfig) =>
            `https://codman.${tenant ?? "dica"}${dotEnv}.nl.mrdm.com/`,
        // shortDescription: "CDM2021",
        iconClass: "codman-tool-icon",
    },
    {
        id: "survey",
        name: "DataEntry",
        appInstances: ["ApplicationInstance.Survey.NL"],
        getUrl: ({ dotEnv }: UrlConfig): string => {
            const currentDotEnv = dotEnv === ".staging" ? ".accept" : dotEnv;
            return `https://dataentry${currentDotEnv}.nl.mrdm.com/select`;
        },
        // shortDescription: "SRV021",
        iconClass: "survey-tool-icon",
    },
    {
        id: "dataConnect",
        name: "Data Connect",
        appInstances: ["ApplicationInstance.DataConnect.NL"],
        getUrl: ({ dotEnv }: UrlConfig) => `https://data-connect${dotEnv}.nl.mrdm.com/`,
        // shortDescription: "DCN2021",
        iconClass: "data-connect-tool-icon",
    },
    {
        id: "medicine",
        nameLc: "APP._Shared.Applications.Medicine.Name",
        appInstances: ["ApplicationInstance.CodmanMedicine.NL"],
        getUrl: () => `https://mva.mrdm.nl.logex.com/`,
        // shortDescription: "MED2021",
        iconClass: "medicine-tool-icon",
    },
    {
        id: "data-request",
        nameLc: "APP._Shared.Applications.DataRequest.Name",
        appInstances: ["ApplicationInstance.DataRequest.NL"],
        getUrl: ({ dotEnv }: UrlConfig) => `https://data-request${dotEnv}.nl.mrdm.com/`,
        // shortDescription: "DRA",
        iconClass: "data-request-tool-icon",
    },
    {
        id: "patients",
        nameLc: "APP._Shared.Applications.Patients.Name",
        appInstances: ["ApplicationInstance.CodmanPatients.NL"],
        getUrl: ({ dotEnv }: UrlConfig) => `https://codman-patients.dica${dotEnv}.nl.mrdm.com/`,
        // shortDescription: "DRA",
        iconClass: "patients-tool-icon",
    },
];
