import { inject, Injectable } from "@angular/core";

import { first } from "rxjs/operators";

import { getColor, LgColorPaletteIdentifiers } from "@logex/framework/lg-charts";

import { IEnvironmentColorTheme, IEnvironmentSettingsTheme } from "@codman/shared/types";

import { SharedConfigService } from "./shared-config.service";

@Injectable({
    providedIn: "root",
})
export class SharedColoringService {
    _sharedConfigService = inject(SharedConfigService);

    private _theme: IEnvironmentColorTheme = {
        provider: getColor(LgColorPaletteIdentifiers.ClinicalOutcomes, 40),
        benchmark: getColor(LgColorPaletteIdentifiers.Grey, 30),
        benchmarkConnect: getColor(LgColorPaletteIdentifiers.ValuePurple, 30),
        benchmarkProviderType: getColor(LgColorPaletteIdentifiers.Cobalt, 30),
        sharedOrganization: getColor(LgColorPaletteIdentifiers.ValuePurple, 60),
    };

    constructor() {
        this._sharedConfigService.configuration$.pipe(first()).subscribe(config => {
            this._theme = this._initializeTheme(config.theme);
        });
    }

    getTheme(): IEnvironmentColorTheme {
        return this._theme;
    }

    private _initializeTheme(environmentTheme?: IEnvironmentSettingsTheme): IEnvironmentColorTheme {
        const theme: IEnvironmentColorTheme = {
            ...this._theme,
        };

        const providerColor = environmentTheme?.provider;
        if (providerColor) {
            theme.provider = getColor(
                providerColor.paletteIdentifier,
                providerColor.lightnessIdentifier,
            );
        }

        const benchmarkColor = environmentTheme?.benchmark;
        if (benchmarkColor) {
            theme.benchmark = getColor(
                benchmarkColor.paletteIdentifier,
                benchmarkColor.lightnessIdentifier,
            );
        }

        const benchmarkConnectColor = environmentTheme?.benchmarkConnect;
        if (benchmarkConnectColor) {
            theme.benchmarkConnect = getColor(
                benchmarkConnectColor.paletteIdentifier,
                benchmarkConnectColor.lightnessIdentifier,
            );
        }

        const sharedOrganizationColor = environmentTheme?.sharedOrganization;
        if (sharedOrganizationColor) {
            theme.sharedOrganization = getColor(
                sharedOrganizationColor.paletteIdentifier,
                sharedOrganizationColor.lightnessIdentifier,
            );
        }

        return theme;
    }
}
