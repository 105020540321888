import { IVersion } from "@logex/framework/lg-application";

import { EnvironmentSettings } from "./environment";

export interface AppConfiguration extends EnvironmentSettings {
    apiUrl: string;
    sharedApiUrl: string;
    overviewApiUrl: string;
    localizationApiUrl: string;
    myAccountUrl: string;
    dotEnv: string;
    dashEnv: string;
}

export interface Version extends IVersion {
    buildNumber: number;
}

export interface IStatus {
    version?: string;
    stage?: string;
    buildTime?: string;
}

export const DEFAULT_DECIMALS = 0;
export const DEFAULT_FORMATTER_TYPE = "float";
export const DEFAULT_ZERO_DASH = false;
export const DEFAULT_FORMATTER_PARAMS = {
    decimals: DEFAULT_DECIMALS,
    zeroDash: DEFAULT_ZERO_DASH,
};
export const DEFAULT_BENCHMARK_TYPE = "national";

export enum Dashboards {
    Exploration = "Exploration",
    Indicators = "Indicators",
    Descriptives = "Descriptives",
    Portal = "Portal",
    Overview = "Overview",
    DataRequest = "DataRequest",

    // specific dashboards
    PatientsLikeMe = "PatientsLikeMe",
    Medicine = "Medicine",
}
