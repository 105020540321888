import { Injectable, inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { LG_APPLICATION_EVENT_TRACER } from "@logex/framework/core";
import { LgMatTrackingService } from "@logex/framework/lg-application";

import { MatomoConfiguration } from "./matomo-configuration.service";
import { Auth0AuthorizationService } from "@codman/shared/util-authorization";
import { first } from "rxjs";

const Category = "Codman";
const Exports = "Exports";
const Highlights = "Highlights";
const BestPractice = "BestPractice";
export type MatomoExportType =
    | "indicatorsOverview"
    | "explorationOverview"
    | "indicatorsDetail"
    | "explorationDetail"
    | "indicatorsPatientList"
    | "indicatorsPatientVariableList"
    | "indicatorsPatientListInTable"
    | "indicatorsPatientVariableListInTable"
    | "explorationPatientList"
    | "decriptivesOverview"
    | "unknownExportType"
    | "patients";

export type MatomoHighlightType = "highlightClick";
export type MatomoBestPracticeType = "bestPracticeOverview" | "bestPracticeDetail";

@Injectable({
    providedIn: "root",
})
export class CodmanEventTracer {
    private _tracer = inject(LG_APPLICATION_EVENT_TRACER);
    private _matomo = inject(LgMatTrackingService);
    private _matomoConfiguration = inject(MatomoConfiguration);
    private _router = inject(Router);
    private _auth0AuthorizationService = inject(Auth0AuthorizationService);

    private readonly _matomoConfig = this._matomoConfiguration.configuration?.matomo;

    setOrganization(organization: number): void {
        this._matomoConfiguration.setOrganizationId(organization);
        if (this._matomoConfig?.organizationDimensionId) {
            this._matomo.setCustomDimension(
                this._matomoConfig.organizationDimensionId,
                organization,
            );
            this._tracer.trackEvent(Category, "organization", organization.toString());
        }
    }

    setPosition(position: string): void {
        this._matomoConfiguration.setUserPosition(position);
        if (this._matomoConfig?.positionDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.positionDimensionId, position);
            this._tracer.trackEvent(Category, "position", position);
        }
    }

    setTenant(tenant: string): void {
        this._matomoConfiguration.setTenant(tenant);
        if (this._matomoConfig?.tenantDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.tenantDimensionId, tenant);
            this._tracer.trackEvent(Category, "tenant", tenant);
        }
    }

    setOrganizationName(organizationName: string): void {
        this._matomoConfiguration.setOrganizationName(organizationName);
        if (this._matomoConfig?.organizationNameDimensionId) {
            this._matomo.setCustomDimension(
                this._matomoConfig.organizationNameDimensionId,
                organizationName,
            );
            this._tracer.trackEvent(Category, "organizationName", organizationName);
        }
    }

    setRegistry(registry: string): void {
        if (this._matomoConfig?.registryDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.registryDimensionId, registry);
            this._tracer.trackEvent(Category, "registryUri", registry);
        }
    }

    setSubset(subset: string): void {
        if (this._matomoConfig?.subsetDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.subsetDimensionId, subset);
            this._tracer.trackEvent(Category, "subsetUri", subset);
        }
    }

    setVersion(version: string): void {
        if (this._matomoConfig?.versionDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.versionDimensionId, version);
            this._tracer.trackEvent(Category, "version", version);
        }
    }

    setPage(page: string): void {
        if (this._matomoConfig?.pageDimensionId) {
            this._matomo.setCustomDimension(this._matomoConfig.pageDimensionId, page);
            this._tracer.trackEvent(Category, "pageUri", page);
        }
    }

    trackBestPractice(type: MatomoBestPracticeType): void {
        this._tracer.trackEvent(BestPractice, type, this._router.url);
    }

    trackExport(type: MatomoExportType): void {
        this._tracer.trackEvent(Exports, type, this._router.url);
    }

    trackHighlight(highlightName: string, registry: string | null): void {
        this._tracer.trackEvent(Highlights, "highlightClick", `${registry}-${highlightName}`);
    }

    trackPatients(registry?: string): void {
        if (registry && this._matomoConfig?.registry) {
            this._matomo.setCustomDimension(this._matomoConfig?.registry, registry);
        }

        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._matomo.pageChange();
            }
        });

        this._auth0AuthorizationService.userProfile$.pipe(first()).subscribe(userProfile => {
            const provider =
                userProfile && userProfile["https://mrdm.io/healthcareserviceproviders"]?.[0];
            if (provider && this._matomoConfig?.provider) {
                this._matomo.setCustomDimension(this._matomoConfig?.provider, provider);
            }
            if (
                registry &&
                provider &&
                this._matomoConfig?.providerRegistry &&
                this._matomoConfig?.registryProvider
            ) {
                const PROVIDER_REGISTRY = `${provider} (${registry})`;
                const REGISTRY_PROVIDER = `${registry} (${provider})`;
                this._matomo.setCustomDimension(
                    this._matomoConfig?.providerRegistry,
                    PROVIDER_REGISTRY,
                );
                this._matomo.setCustomDimension(
                    this._matomoConfig?.registryProvider,
                    REGISTRY_PROVIDER,
                );
            }
            const position = userProfile && userProfile["https://mrdm.io/position"];
            if (position && this._matomoConfig?.position) {
                this._matomo.setCustomDimension(this._matomoConfig?.position, position);
            }
            this._matomo.pageChange();
        });
    }
}
