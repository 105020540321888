import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { UnsubscribedComponent } from "./unsubscribed/unsubscribed.component";

@NgModule({
    imports: [CommonModule, UiCoreModule, LgLocalizationModule],
    declarations: [AccessDeniedComponent, UnsubscribedComponent],
    exports: [AccessDeniedComponent, UnsubscribedComponent],
})
export class SharedFeatureStaticPagesModule {}
