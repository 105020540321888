import { BehaviorSubject, Observable } from "rxjs";

import { Auth0AuthorizationService, Auth0User } from "@codman/shared/util-authorization";
import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";
import { inject } from "@angular/core";

export abstract class StaticPageBase {
    private _auth0AuthorizationService = inject(Auth0AuthorizationService);
    _sharedConfigService = inject(SharedConfigService);

    _userProfile$: Observable<Auth0User | undefined>;
    _portalUrl?: string;
    _myAccountUrl?: string;
    _secondsToRedirect$: BehaviorSubject<number> = new BehaviorSubject(10);

    constructor() {
        this._userProfile$ = this._auth0AuthorizationService.userProfile$;
        const config = this._sharedConfigService.getConfiguration();
        const tenant = config.tenant ?? "dica";
        if (config) {
            this._portalUrl = `https://codman.${tenant}${config.dotEnv}.${config.domain}`;
            this._myAccountUrl = config.myAccountUrl;
            this._tick();
        }
    }

    _tick(): void {
        setTimeout(() => {
            this._secondsToRedirect$.next(this._secondsToRedirect$.value - 1);
            if (this._secondsToRedirect$.value === 0) {
                if (this._portalUrl) window.location.href = this._portalUrl;
            } else {
                this._tick();
            }
        }, 1000);
    }

    _logout(): void {
        this._auth0AuthorizationService.logout();
    }
}
