import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { map } from "rxjs/operators";

import { LG_AUTHENTICATION_SERVICE } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";

import { Auth0AuthenticationService } from "./auth0-authentication.service";
import { AUTH0_CONFIG } from "./auth0-config";
import { Auth0InterceptorService } from "./auth0-interceptor.service";

@NgModule({
    imports: [],
})
export class SharedUtilAuthorizationModule {
    static forRoot(): ModuleWithProviders<SharedUtilAuthorizationModule> {
        return {
            ngModule: SharedUtilAuthorizationModule,
            providers: [
                {
                    provide: AUTH0_CONFIG,
                    useFactory: (configService: SharedConfigService) =>
                        configService.configuration$
                            .pipe(
                                map(config => ({
                                    redirectUri: location.origin,
                                    ...config.auth0,
                                })),
                            )
                            .toPromise(),
                    deps: [SharedConfigService],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: Auth0InterceptorService,
                    multi: true,
                },
                {
                    provide: LG_AUTHENTICATION_SERVICE,
                    useClass: Auth0AuthenticationService,
                },
            ],
        };
    }
}
