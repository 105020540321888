import { Injectable } from "@angular/core";

import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    MatomoStatsUrl,
} from "@logex/framework/lg-application";

import { AppConfiguration } from "@codman/shared/types";

@Injectable({ providedIn: "root" })
export class MatomoConfiguration implements IMatomoConfiguration {
    statUrl: string = MatomoStatsUrl.MRDM;
    siteId!: number; // this is a lie, but we need to improve fw typing first
    commonDimensionIds: IMatomoCommonDimensionIds = {};
    customDimensions = (): IMatomoDimension[] => this._getCustomDimensions();
    autoInit = true;
    autoTrack = true;
    configuration: AppConfiguration | undefined;

    private _organizationId: number | undefined;
    private _userPosition: string | undefined;
    private _tenant: string | undefined;
    private _organizationName: string | undefined;

    configure(config: AppConfiguration): void {
        if (this.configuration) throw new Error("Configuration was already set");
        this.configuration = config;
        this.siteId = config.matomo?.siteId;
    }

    doNotDoMatTracking(): boolean {
        return window.location.hostname === "localhost";
    }

    setOrganizationId(organizationId: number): void {
        this._organizationId = organizationId;
    }

    setUserPosition(position: string | undefined): void {
        this._userPosition = position;
    }

    setTenant(tenant: string): void {
        this._tenant = tenant;
    }

    setOrganizationName(organizationName: string): void {
        this._organizationName = organizationName;
    }

    _getCustomDimensions(): IMatomoDimension[] {
        const result: IMatomoDimension[] = [];

        if (this._organizationId) {
            result.push({
                id: this.configuration!.matomo.organizationDimensionId,
                value: this._organizationId,
            });
        }

        if (this._userPosition) {
            result.push({
                id: this.configuration!.matomo.positionDimensionId,
                value: this._userPosition,
            });
        }

        if (this._tenant) {
            result.push({
                id: this.configuration!.matomo.tenantDimensionId,
                value: this._tenant,
            });
        }

        if (this._organizationName) {
            result.push({
                id: this.configuration!.matomo.organizationNameDimensionId,
                value: this._organizationName,
            });
        }

        return result;
    }
}
