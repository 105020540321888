import { Injectable, Injector, inject, isDevMode } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { first, tap } from "rxjs/operators";

import { LgConfirmationDialog, IConfirmDialogOptions } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { AppConfiguration } from "@codman/shared/types";

import { AUTHORIZATION_API_USER, SharedConfigService } from "./shared-config.service";

export interface IHttpErrorResponse extends HttpErrorResponse {
    error: IError | null;
}

export interface IError {
    message: string;
}

const BAD_REQUEST_400 = 400;
const UNPROCESSABLE_CONTENT_422 = 422;
const UNAUTHORIZED_401 = 401;

@Injectable()
export class CodmanBackendInterceptor implements HttpInterceptor {
    _sharedConfigService = inject(SharedConfigService);
    private _injector = inject(Injector);
    private _lgTranslateService = inject(LgTranslateService);

    private static _sessionDialogVisible = false;
    private _portalUrl: string | null = null;

    /**
     * This is necessary to break the dependency chain during app initialization
     * This interceptor is provided in app modules, but since it injects LgConfirmationDialog directly,
     * the dialog then injects LG_APP_INFO which is done before SharedConfigService actually loads
     */
    private _lgConfirmationDialogInstance: LgConfirmationDialog | null = null;
    private get _lgConfirmationDialog(): LgConfirmationDialog {
        if (this._lgConfirmationDialogInstance === null) {
            this._lgConfirmationDialogInstance = this._injector.get(LgConfirmationDialog);
        }
        return this._lgConfirmationDialogInstance;
    }

    constructor() {
        this._sharedConfigService.configuration$.pipe(first()).subscribe(configuration => {
            this._portalUrl = this._getPortalUrl(configuration);
        });
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap({
                error: (response: IHttpErrorResponse) => {
                    if (response?.status === UNAUTHORIZED_401 && response?.error?.message == null) {
                        if (!CodmanBackendInterceptor._sessionDialogVisible) {
                            CodmanBackendInterceptor._sessionDialogVisible = true;
                            this._lgConfirmationDialog
                                .alert(
                                    this._lgTranslateService.translate(
                                        "FW.FAILED_REQUEST_TO_SERVER",
                                    ),
                                    this._lgTranslateService.translate("FW.SESSION_EXPIRED"),
                                    { allowClose: false },
                                )
                                .then(() => {
                                    window.location.reload();
                                });
                        }
                    } else if (
                        response.status === UNPROCESSABLE_CONTENT_422 &&
                        req.method === "GET" &&
                        req.url.includes(AUTHORIZATION_API_USER)
                    ) {
                        // user account doesn't exist, we show no server error
                    } else if (response.status >= BAD_REQUEST_400 && req.method === "GET") {
                        const isDebug: boolean = isDevMode();
                        const error = this._getGeneralError(response, isDebug);

                        const dialogOptions: IConfirmDialogOptions = {
                            button1: this._lgTranslateService.translate("FW.RETRY"),
                            button1Icon: "",
                            button2: this._lgTranslateService.translate("FW.CANCEL"),
                            button2Icon: "",
                            dialogType: "alert",
                            columns: 5,
                        };
                        if (isDebug) dialogOptions.button3 = "Ignore";

                        this._showDialog(error, dialogOptions);
                    }
                },
            }),
        );
    }

    private _getPortalUrl(configuration: AppConfiguration): string {
        const { tenant, environment, domain } = configuration;
        if (environment === "prod") {
            return `https://codman.${tenant ?? "dica"}.${domain}`;
        } else {
            return `https://codman.${tenant ?? "dica"}.${environment}.${domain}`;
        }
    }

    private _getGeneralError(response: IHttpErrorResponse, isDebug: boolean): string {
        let error = "Error status " + response.status;

        if (isDebug && response.error?.message) {
            error += ": " + response.error.message;
            // if (e.error.value.stackTrace) {
            //     error += `<br /><div class="confirm-message__section confirm-message__scrollable" ><b>Stack:</b><br />${e.error.StackTrace.replace(
            //         /\n/g,
            //         "<br/><br />",
            //     )}</div>`;
            //     console.log(e.error.ExceptionMessage);
            //     console.log(e.error.StackTrace);
            // }}
        } else {
            error = this._lgTranslateService.translate("APP._Shared.ServerError");
        }

        return error;
    }

    private _showDialog(error: string, dialogOptions: IConfirmDialogOptions): void {
        this._lgConfirmationDialog
            .confirm(
                this._lgTranslateService.translate("FW.FAILED_REQUEST_TO_SERVER"),
                error + "<br />",
                dialogOptions,
            )
            .then(retry => {
                if (retry === 1) {
                    window.location.reload();
                } else if (retry === 2) {
                    if (this._portalUrl) {
                        window.location.href = this._portalUrl;
                    } else {
                        window.location.assign("");
                    }
                }
            });
    }
}
