import { IUser } from "@logex/framework/lg-application";

export class SharedAppUser implements IUser {
    id = 0;
    name = "";
    roles: _.Dictionary<boolean> = {};
    title = "";
    userid = "";
    ziekenhuiscode = 0;
    ziekenhuisnaam = "";
    impersonator = "";
    impersonatorName = "";
    email = "";

    public hasPermission(role: string): boolean {
        if (role === "switch_language") {
            // hardcode to show language switch
            return true;
        }
        return this.roles[role] || false;
    }
}
