import { CodmanApplication } from "./codman-applications";

export interface UserNotification {
    enabled: boolean;
    title: string;
    text: string;
    readMoreUrl?: string;

    application: CodmanApplication;
    tenants?: NotificationTarget;
    registries?: NotificationTarget;
    pages?: NotificationTarget;
}

export interface NotificationTarget {
    allExcept?: string[];
    specific?: string[];
}

/**
 * A list of notifications to display. Both title and text can include a translation string.
 * 'Application', 'tenants', 'registries' and 'pages' properties represent a location
 * indicating where a notification should be shown. Options for tenants, registries and pages:
 * 1. undefined - all tenants/registries/pages are targeted
 * 2. specific - selected tenants/registries/pages are targeted
 * 3. allExcept - all tenants/registries/pages are targeted except for specified ones
 */
export const notificationsConfig: UserNotification[] = [
    // Descriptives
    {
        enabled: false,
        application: "descriptives",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            allExcept: [],
        },
        pages: {
            specific: ["default-2021"],
        },

        title: "APP.Notifications.Default2021.Title",
        text: "APP.Notifications.Default2021.Text",
    },
    {
        enabled: false,
        application: "descriptives",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            specific: [],
        },
        pages: {
            specific: ["default-2022"],
        },

        title: "APP.Notifications.Default2022.Title",
        text: "APP.Notifications.Default2022.Text",
    },
    {
        enabled: false,
        application: "descriptives",
        tenants: {
            specific: ["dica"],
        },
        pages: {
            specific: ["default-2022"],
        },

        title: "APP.Notifications.Default2022Converted.Title",
        text: "APP.Notifications.Default2022Converted.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/beschrijvingen/introductie/verslagjaren-en-conversies/",
    },
    {
        enabled: false,
        application: "descriptives",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            allExcept: [],
        },
        pages: {
            specific: ["default-2023"],
        },

        title: "APP.Notifications.Default2023.Title",
        text: "APP.Notifications.Default2023.Text",
    },
    {
        enabled: true,
        application: "descriptives",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            allExcept: [
                "Daci",
                "Dasa",
                "Dato",
                "Dbir",
                "Dcra",
                "DgeaDrce",
                "Dgda",
                "Dgoa",
                "Dhba",
                "Dhfa",
                "Dhna",
                "Diha",
                "DlcaL",
                "DlcaR",
                "DlcaS",
                "Dmtr",
                "Dnma",
                "Dpa",
                "Dpaa",
                "Dpard",
                "Dpca",
                "Dppa",
                "Dsaa",
                "Duca",
                "Epsa",
                "Nbca",
                "NbcaR",
            ],
        },
        pages: {
            specific: ["default-2024"],
        },

        title: "APP.Notifications.Default2024.Title",
        text: "APP.Notifications.Default2024.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/beschrijvingen/introductie/verslagjaren-en-conversies/",
    },
    // Exploration
    {
        enabled: false,
        application: "exploration",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            specific: [],
        },

        title: "APP.Notifications.Data2021.Title",
        text: "APP.Notifications.Data2021.Text",
    },
    {
        enabled: false,
        application: "exploration",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            allExcept: [],
        },

        title: "APP.Notifications.Data2022.Title",
        text: "APP.Notifications.Data2022.Text",
    },
    {
        enabled: true,
        application: "exploration",
        tenants: {
            specific: ["dica"],
        },
        registries: {
            allExcept: [
                "Dasa",
                "Dato",
                "Dbir",
                "Dcra",
                "Dgda",
                "DgeaDrce",
                "Dgoa",
                "Dhba",
                "Dhfa",
                "Dhna",
                "Diha",
                "DlcaL",
                "DlcaR",
                "DlcaS",
                "Dmtr",
                "Dnma",
                "Dpaa",
                "Dpard",
                "Dpca",
                "Dppa",
                "Dsaa",
                "Duca",
                "Epsa",
                "Nbca",
            ],
        },

        title: "APP.Notifications.Data2023.Title",
        text: "APP.Notifications.Data2023.Text",
    },
    // Indicators
    {
        enabled: false,
        application: "indicators",
        tenants: {
            specific: ["dica"],
        },
        pages: {
            specific: ["definitions2022"],
        },

        title: "APP.Notifications.Definitions2022.Title",
        text: "APP.Notifications.Definitions2022.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/indicatoren/introductie/tp-periode/verslagjaren/",
    },
    {
        enabled: false,
        application: "indicators",
        tenants: {
            specific: ["dica"],
        },
        pages: {
            specific: ["definitions2023"],
        },
        registries: {
            allExcept: [],
        },

        title: "APP.Notifications.Definitions2023.Title",
        text: "APP.Notifications.Definitions2023.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/indicatoren/introductie/tp-periode/verslagjaren/",
    },
    {
        enabled: true,
        application: "indicators",
        tenants: {
            specific: ["dica"],
        },
        pages: {
            specific: ["definitions2024"],
        },
        registries: {
            allExcept: [
                "Daci",
                "Dasa",
                "Dato",
                "Dbir",
                "Dcra",
                "DgeaDrce",
                "Dgoa",
                "Dhba",
                "Dhfa",
                "Dhna",
                "DlcaL",
                "DlcaR",
                "DlcaS",
                "Dmtr",
                "Dnma",
                "Dpard",
                "Dpca",
                "Dsaa",
                "Duca",
                "Epsa",
                "Nbca",
                "NbcaR",
            ],
        },

        title: "APP.Notifications.Definitions2024.Title",
        text: "APP.Notifications.Definitions2024.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/indicatoren/introductie/tp-periode/verslagjaren/",
    },
    // Overview
    {
        enabled: false,
        application: "overview",
        title: "APP.Notifications.Data2023.Title",
        text: "APP.Notifications.Data2023.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/indicatoren/introductie/tp-periode/verslagjaren/",
    },
    {
        enabled: false,
        application: "overview",
        title: "APP.Notifications.Data2024.Title",
        text: "APP.Notifications.Data2024.Text",
        readMoreUrl:
            "https://support.mrdm.com/nl/codman/indicatoren/introductie/tp-periode/verslagjaren/",
    },
];
