import { FeatureFlagsAppConfig } from "./types";

export const dataRequest: FeatureFlagsAppConfig = {
    local: {
        all: {},
    },
    test: {
        all: {},
    },
    accept: {
        all: {},
    },
    staging: {
        all: {},
    },
    demo: {
        all: {},
    },
    prod: {
        all: {},
    },
};
