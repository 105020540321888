/*
 * Public API Surface of shared-authorization
 */

export * from "./lib/auth0-authorization.service";
export * from "./lib/auth0-config";
export * from "./lib/auth0-guard";
export * from "./lib/shared-util-authorization.module";
export * from "./lib/auth0-user";
export * from "./lib/auth0-interceptor.service";
