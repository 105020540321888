/**
 * The environment structure is follows:
 * - production cluster:
 *   - `prod` is a live environment
 * - accept cluster (same authorization):
 *   - `accept` is a ready to release code, but testing environment for R-scripted data
 *   - `staging` is a ready to release code and data
 *   - `demo` is a presentation environment
 * - test cluster:
 *   - `test` environment serves as first deployed code
 * - `local` serves for both frontend and backend deployed on localhost
 */
export type Environment = "local" | "test" | "accept" | "staging" | "demo" | "prod";
