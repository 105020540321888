import { Injectable } from "@angular/core";

const SMALL_SCREEN = 1536;

@Injectable({
    providedIn: "root",
})
export class SharedBreadcrumbNarrowingService {
    private _breadcrumbNarrowing: Record<number, boolean> = {};

    public setBreadcrumbNarrowing(narrowing: Record<number, boolean>): void {
        this._breadcrumbNarrowing = { ...narrowing };
    }

    public setBreadcrumbNarrowingAutomatic(
        narrowing: Record<number, boolean>,
        width: number,
    ): void {
        if (width <= SMALL_SCREEN) {
            this._breadcrumbNarrowing = { ...narrowing };
        } else {
            this.resetBreadcrumbNarrowing();
        }
    }

    public getBreadcrumbNarrowing(): Record<number, boolean> {
        return { ...this._breadcrumbNarrowing };
    }

    public resetBreadcrumbNarrowing(): void {
        this._breadcrumbNarrowing = {};
    }
}
