import { IMatomo } from "@codman/shared/types";

const MATOMO_SETTINGS: IMatomo = {
    siteId: 60,
    organizationDimensionId: 1,
    positionDimensionId: 2,
    registryDimensionId: 3,
    subsetDimensionId: 4,
    pageDimensionId: 5,
    tenantDimensionId: 6,
    organizationNameDimensionId: 7,
    registry: 8,
    provider: 9,
    providerRegistry: 10,
    registryProvider: 11,
    position: 12,
};

export class CodmanMockEventTracer {
    private readonly _matomoConfig = MATOMO_SETTINGS;

    setOrganization(organization: number): void {
        //
    }

    setPosition(position: string): void {
        //
    }

    setTenant(tenant: string): void {
        //
    }

    setOrganizationName(organizationName: string): void {
        //
    }

    setRegistry(registry: string): void {
        //
    }

    setSubset(subset: string): void {
        //
    }

    setVersion(version: string): void {
        //
    }

    setPage(page: string): void {
        //
    }

    trackPatients(registry: string): void {
        //
    }
}
