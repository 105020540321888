import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";
import { appRoutes } from "./app.routes";
import {
    SharedConfigService,
    SharedUtilLogexFrameworkRootModule,
} from "@codman/shared/util-logex-framework-setup";
import {
    LG_APP_INFO,
    LG_MATOMO_CONFIGURATION,
    LG_NAVIGATION,
} from "@logex/framework/lg-application";
import { HttpClientModule } from "@angular/common/http";
import { SharedUtilAuthorizationModule } from "@codman/shared/util-authorization";
import { MatomoConfiguration } from "@codman/shared/util-tracking";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes),
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            HttpClientModule,
            SharedUtilLogexFrameworkRootModule,
            SharedUtilAuthorizationModule.forRoot(),
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: SharedConfigService) => startup.initialize(),
            deps: [SharedConfigService],
            multi: true,
        },
        {
            provide: LG_NAVIGATION,
            useValue: [],
        },
        {
            provide: LG_APP_INFO,
            useFactory: (configService: SharedConfigService) => {
                const config = configService.getConfiguration();
                const buildNumber = config?.version;
                return {
                    environment: config?.environment,
                    fullAppName: "Etl",
                    productId: "etl",
                    toolInstanceName: "",
                    versionNumber: `2.${buildNumber ?? "000"}`,
                    overrideCurrencyPrefix: "",
                    overrideCurrencySuffix: "",

                    doNotDoGaTracking: () => false,
                    isProduction: () => config?.environment === "prod",
                };
            },
            deps: [SharedConfigService],
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration,
        },
    ],
};
