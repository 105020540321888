import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { map, shareReplay, tap } from "rxjs/operators";
import { Observable, Subject } from "rxjs";

import { IAppConfiguration } from "@logex/framework/lg-application";

import { MatomoConfiguration } from "@codman/shared/util-tracking";
import {
    AppConfiguration,
    Dashboards,
    EnvironmentSettings,
    IEnvironmentSettingsLayout,
    UserflowUserAttributes,
} from "@codman/shared/types";
import { CodmanApplication, InsightsApplication } from "@codman/shared/assets";
import { HelpLink, HelpLinksId, getSupportLinksHeader } from "./help-links";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { APP_CA_INSTANCE_LOOKUP, APP_INSTANCE_LOOKUP } from "./shared-lookups";

const DYNAMIC_LOCALIZATION_APP = "exploration";
export const AUTHORIZATION_API_USER = "api/v3/user";
export const AUTHORIZATION_API_ACCESS = "api/v3/access";

@Injectable({
    providedIn: "root",
})
export class SharedConfigService {
    private _http = inject(HttpClient);
    private _matomoConfiguration = inject(MatomoConfiguration);

    private _sharedApiUrl: string | null = null;
    private _configuration: AppConfiguration | null = null;
    readonly configuration$: Observable<AppConfiguration> = this._http
        .get<EnvironmentSettings>("assets/config/env-settings.json")
        .pipe(
            map(config => ({
                ...config,
                apiUrl: this._getApiUrl(config),
                overviewApiUrl: this._getOverviewApiUrl(config),
                sharedApiUrl: this._getSharedApiUrl(config),
                localizationApiUrl: this._getLocalizationUrl(config),
                myAccountUrl: this._getMyAccountUrl(config),
                dotEnv: config.environment === "prod" ? "" : "." + config.environment,
                dashEnv: config.environment === "prod" ? "" : "-" + config.environment,
            })),
            tap(config => this._initialize(config)),
            shareReplay(1),
        );

    private _layout: IEnvironmentSettingsLayout = {
        sharedUiFooterShowDicaLogo: true,
        sharedUiFooterLocalizationId: "APP._Shared.Footer",
    };

    private _appConfigurationReady$ = new Subject<void>();
    private _appConfiguration: IAppConfiguration = {
        instance: "",
        environment: "",
        applicationInsightsInstrumentationKey: "",
        applicationInsightsExcludeDomains: [],
        userflowEnvironmentKey: "",
        userflowContentId: "",
        logoutUrl: "",
        switchHospital: true,
        switchHospitalUrl: "",
        switchMergedHospitals: true,
        testMachine: "",
        applicationRoot: "",
        ready: this._appConfigurationReady$.toPromise(),
    };

    private readonly _notInitializedErrorMessage = "Configuration has not yet been initialised.";

    _userflowUserAttributes?: UserflowUserAttributes;

    get sharedApiUrl(): string | null {
        return this._sharedApiUrl;
    }

    initialize(): () => Observable<AppConfiguration> {
        return () => this.configuration$;
    }

    getConfiguration(): AppConfiguration {
        if (this._configuration) {
            return this._configuration;
        } else {
            throw new Error(this._notInitializedErrorMessage);
        }
    }

    getCountry(): string {
        const config = this.getConfiguration();
        return config?.domain.split(".")[0].toUpperCase() ?? "";
    }

    getAppInstanceLookUp(app: CodmanApplication): string {
        return this.getCountry() === "CA" ? APP_CA_INSTANCE_LOOKUP[app] : APP_INSTANCE_LOOKUP[app];
    }

    getAppConfiguration(): IAppConfiguration {
        return this._appConfiguration;
    }

    getLayout(): IEnvironmentSettingsLayout {
        return this._layout;
    }

    get userflowUserAttributes(): UserflowUserAttributes {
        if (this._userflowUserAttributes) {
            return this._userflowUserAttributes;
        } else {
            throw new Error(this._notInitializedErrorMessage);
        }
    }

    getApiUrl(application: InsightsApplication): string {
        const config = {
            ...this.getConfiguration(),
            application,
        };
        return this._getApiUrl(config);
    }

    getSupportLinksHeader(
        dashboard: Dashboards | undefined,
        tenant: string | undefined,
        registryId: string | null,
        translateService: LgTranslateService,
    ): Partial<{ [key in HelpLinksId]: HelpLink }> {
        return getSupportLinksHeader(dashboard, tenant, registryId, translateService);
    }

    private _initialize(config: AppConfiguration): void {
        this._configuration = config;
        this._sharedApiUrl = config.sharedApiUrl;
        this._layout = this._getLayout(config);
        this._userflowUserAttributes = this._getUserflowUserAttributes(config);
        this._matomoConfiguration.configure(config);

        this._appConfiguration = this._getAppConfiguration(config);
        this._appConfigurationReady$.next();
        this._appConfigurationReady$.complete();
    }

    private _getApiUrl(config: EnvironmentSettings): string {
        if (config.application === "patients") return this._getPatientsApiUrl(config);

        const dotTenant = config.tenant ? `.${config.tenant}` : "";
        if (config.environment === "prod") {
            return `https://${config.application}-reporting${dotTenant}.${config.domain}`;
        } else if (config.environment === "local") {
            const apiPort = config.apiPort ?? 5001;
            return `https://localhost:${apiPort}`;
        } else {
            return `https://${config.application}-reporting${dotTenant}.${config.environment}.${config.domain}`;
        }
    }

    private _getPatientsApiUrl(config: EnvironmentSettings): string {
        if (config.environment === "prod") {
            return `https://dcra.valuebase.nl/patientslikeme`;
        } else {
            return `https://dcra-${config.environment}.valuebase.nl/patientslikeme`;
        }
    }

    private _getOverviewApiUrl(config: EnvironmentSettings): string {
        const dotTenant = config.tenant ? `.${config.tenant}` : "";
        if (config.environment === "prod") {
            return `https://overview-reporting${dotTenant}.${config.domain}`;
        } else if (config.environment === "local") {
            const apiPort = config.apiPort ?? 5001;
            return `https://localhost:${apiPort}`;
        } else {
            return `https://overview-reporting${dotTenant}.${config.environment}.${config.domain}`;
        }
    }

    private _getSharedApiUrl(config: EnvironmentSettings): string {
        if (config.environment === "prod") {
            return `https://shared-reporting.nl.mrdm.com`;
        } else {
            return `https://shared-reporting.${config.environment}.nl.mrdm.com`;
        }
    }

    private _getLocalizationUrl(config: EnvironmentSettings): string {
        if (config.environment === "prod") {
            return `https://${DYNAMIC_LOCALIZATION_APP}-reporting.${config.tenant}.${config.domain}`;
        } else if (config.environment === "local") {
            const apiPort = config.apiPort ?? 5001;
            return `https://localhost:${apiPort}`;
        } else {
            const domain = "nl.mrdm.com";
            return `https://${DYNAMIC_LOCALIZATION_APP}-reporting.${config.tenant}.${config.environment}.${domain}`;
        }
    }

    private _getMyAccountUrl(config: EnvironmentSettings): string {
        if (config.environment === "prod") {
            return `https://myaccount.mrdm.com/`;
        } else if (config.environment === "staging") {
            return `https://myaccount-accept.mrdm.com/`;
        } else {
            return `https://myaccount-${config.environment}.mrdm.com/`;
        }
    }

    private _getAppConfiguration(config: AppConfiguration): IAppConfiguration {
        return {
            ...this._appConfiguration,
            userflowEnvironmentKey: config?.userflow?.environmentKey,
            userflowContentId: config?.userflow?.contentId,
            environment: config.environment,
            instance: config.application,
            applicationRoot: config.apiUrl,
        };
    }

    private _getLayout(config: EnvironmentSettings): IEnvironmentSettingsLayout {
        const layout = config.layout ?? null;

        return {
            ...this._layout,
            ...layout,
        };
    }

    private _getUserflowUserAttributes({
        dotEnv,
        tenant,
    }: AppConfiguration): UserflowUserAttributes {
        return {
            dotEnv,
            tenant,
            tenantAbbreviation: tenant?.toUpperCase(),
        };
    }
}
