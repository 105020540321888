import { FeatureFlagsConfig } from "./types";

import { exploration } from "./exploration";
import { indicators } from "./indicators";
import { descriptives } from "./descriptives";
import { portal } from "./portal";
import { patients } from "./patients";
import { overview } from "./overview";
import { dataRequest } from "./data-request";
import { shared } from "./shared";

export * from "./types";

export const featureFlags: FeatureFlagsConfig = {
    exploration,
    indicators,
    descriptives,
    portal,
    patients,
    overview,
    "data-request": dataRequest,
    shared,
} as const;
