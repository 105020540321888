import { FeatureFlagsAppConfig } from "./types";

export const patients: FeatureFlagsAppConfig = {
    local: {
        all: {},
        dica: {},
    },
    test: {
        all: {},
        dica: {},
    },
    accept: {
        all: {},
        dica: {},
    },
    staging: {
        all: {},
        dica: {},
    },
    demo: {
        all: {},
        dica: {},
    },
    prod: {
        all: {},
        dica: {},
    },
};
