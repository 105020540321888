import { InjectionToken } from "@angular/core";

// TODO: consider adding configuration of post login / logout / authorization behaviour
export interface Auth0Config {
    domain: string;
    clientId: string;
    redirectUri?: string;
    audience: string;
    scope?: string;
    logoutReturnToUri?: string;
    access_types?: string[];

    // If true, getTokenSilently will be automatically called when isAuthenticated returns false
    forceTicketCheck?: boolean;
    // If true, we won't detect identity change, and newProfile$ will never trigger
    disableIdentityChangeCheck?: boolean;
}

export const AUTH0_CONFIG = new InjectionToken<Promise<Auth0Config>>("Auth0Config");
