import { Injectable } from "@angular/core";
import { of } from "rxjs";

import { IAppConfiguration } from "@logex/framework/lg-application";

import {
    AppConfiguration,
    IEnvironmentSettingsLayout,
    UserflowUserAttributes,
} from "@codman/shared/types";
import { CodmanApplication } from "@codman/shared/assets";
import { HelpLink } from "./help-links";
import { APP_INSTANCE_LOOKUP } from "./shared-lookups";

@Injectable({
    providedIn: "root",
})
export class SharedConfigMockService {
    private static MockConfiguration: AppConfiguration = {
        application: "exploration",
        applicationInstance: "ApplicationInstance.CodmanExploration.NL",
        environment: "test",
        tenant: "dica",
        domain: "nl.mrdm.com",
        version: "abcdef.000",
        apiUrl: "https://exploration-reporting.dica.test.nl.mrdm.com/",
        sharedApiUrl: "https://shared-reporting.dica.test.nl.mrdm.com/",
        overviewApiUrl: "https://overview-reporting.dica.test.nl.mrdm.com/",
        localizationApiUrl: "https://exploration-reporting.dica.test.nl.mrdm.com/",
        myAccountUrl: "https://shared-reporting.test.nl.mrdm.com/",
        dotEnv: ".test",
        dashEnv: "-test",
        auth0: {
            domain: "accounts-test.mrdm.io",
            clientId: "lRVgFROAGlxOS7BFMVyK7A4BSDmKBQCL",
            audience: "https://codman.mrdm.io",
        },
        authApiUrl: "https://user-authorization-api-test.mrdm.eu/",
        matomo: {
            siteId: 60,
            organizationDimensionId: 1,
            positionDimensionId: 2,
            registryDimensionId: 3,
            subsetDimensionId: 4,
            pageDimensionId: 5,
            tenantDimensionId: 6,
            organizationNameDimensionId: 7,
            registry: 8,
            provider: 9,
            providerRegistry: 10,
            registryProvider: 11,
            position: 12,
        },
        userflow: {
            environmentKey: "ct_m3rb6xnxwjfeffwtzgph2xjtmq",
            contentId: "7b2fd87e-a0ca-4e32-9614-f47606fd1074",
        },
        disableInContext: true,
    };

    configuration$ = of(SharedConfigMockService.MockConfiguration);

    get sharedApiUrl(): string | null {
        return "https://shared-reporting.dica.test.valuebase.nl/";
    }

    getConfiguration(): AppConfiguration | null {
        return SharedConfigMockService.MockConfiguration;
    }

    getAppConfiguration(): IAppConfiguration {
        return {
            instance: "",
            environment: "",
            applicationInsightsInstrumentationKey: "",
            logoutUrl: "",
            switchHospital: true,
            switchHospitalUrl: "",
            switchMergedHospitals: true,
            testMachine: "",
            applicationRoot: "",
            ready: Promise.resolve(),
        };
    }

    getLayout(): IEnvironmentSettingsLayout {
        return {
            sharedUiFooterShowDicaLogo: true,
            sharedUiFooterLocalizationId: "APP._Shared.Footer",
        };
    }

    get userflowUserAttributes(): UserflowUserAttributes {
        return {
            dotEnv: ".test",
            tenant: "dica",
            tenantAbbreviation: "DICA",
        };
    }

    getApiUrl(application: CodmanApplication): string {
        return `https://${application}-reporting.dica.test.nl.mrdm.com`;
    }

    getSupportLinksHeader(): Record<string, HelpLink> {
        return {
            manual: {
                name: "manual",
                icon: "icon-manual",
                order: 1,
                url: "https://support.mrdm.com/en/codman/general-information/",
            },
            FAQ: {
                name: "FAQ",
                icon: "icon-faq",
                order: 2,
                url: "https://support.mrdm.com/nl/codman/veelgestelde-vragen/",
            },
        };
    }

    getAppInstanceLookUp(app: CodmanApplication): string {
        return APP_INSTANCE_LOOKUP[app];
    }

    getCountry(): string {
        return "NL";
    }
}
