import { inject, Injectable } from "@angular/core";
import { Location } from "@angular/common";

import { combineLatest, firstValueFrom, Observable, OperatorFunction, pipe } from "rxjs";
import { map, filter, tap, withLatestFrom } from "rxjs/operators";
import { Dictionary } from "lodash";

import { LgLocalizationSettings, LocalizationJson } from "@logex/framework/lg-localization";

import {
    ALL_REGISTRIES,
    availableLanguages,
    CodmanApplication,
    defaultLanguage,
    fallbackLanguage,
    registryLocalizationConfiguration,
    RegistryTenant,
    REGISTRY_TENANTS_LOOKUP,
} from "@codman/shared/assets";

import { SharedConfigService } from "./shared-config.service";

@Injectable({
    providedIn: "root",
})
export class SharedLocalizationSettingsService implements LgLocalizationSettings {
    private _configService = inject(SharedConfigService);
    private _location = inject(Location);

    readonly bootstrapDone: Promise<void>;

    readonly languages: _.Dictionary<LocalizationJson[]> = {};

    private _preferredLanguage = defaultLanguage;

    private readonly _preferredLanguageKey = "preferredLanguage";
    private readonly _savedPathKey = "savedPath";

    constructor() {
        const bootstrapDone$: Observable<void> = this._configService.configuration$.pipe(
            tap(configuration => {
                const storedPreferredLanguage = localStorage.getItem(this._preferredLanguageKey);
                if (storedPreferredLanguage != null) {
                    this._preferredLanguage = storedPreferredLanguage;
                    return;
                }
                this._saveCurrentPathToSessionStorage();
                const tenant = configuration.tenant;
                if (tenant) {
                    this._preferredLanguage =
                        this.findRegistrySpecificLanguage(tenant) ?? defaultLanguage;
                    return;
                }
                this._preferredLanguage = defaultLanguage;
            }),
            // retype to void
            map(() => undefined),
        );

        this.bootstrapDone = firstValueFrom(bootstrapDone$);
    }

    get fallbackLanguage(): string {
        return fallbackLanguage;
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return availableLanguages;
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    getRegistryLanguages$(
        registry$: Observable<string>,
        application: CodmanApplication,
    ): Observable<string[]> {
        return registry$.pipe(
            map(registry => {
                const registryConfiguration = registryLocalizationConfiguration[registry];
                return (
                    registryConfiguration?.dashboardLanguages?.[application] ?? [
                        registryConfiguration?.defaultLanguage ?? defaultLanguage,
                    ]
                );
            }),
        );
    }

    isLanguageSwitchEnabled$(
        registry$: Observable<string>,
        application: CodmanApplication,
    ): Observable<boolean> {
        return combineLatest([
            this.getRegistryLanguages$(registry$, application),
            this._configService.configuration$,
        ]).pipe(
            map(([registryLanguages]) => registryLanguages.length > 1),
            this._selectDefaultLanguageWhenSwitchIsDisabled(registry$, application),
        );
    }

    private _selectDefaultLanguageWhenSwitchIsDisabled(
        registry$: Observable<string>,
        application: CodmanApplication,
    ): OperatorFunction<boolean, boolean> {
        return pipe(
            withLatestFrom(registry$),
            tap(([isLanguageSwitchEnabled, registry]) => {
                const registryConfiguration = registryLocalizationConfiguration[registry];
                const defaultRegistryLanguage =
                    registryConfiguration?.dashboardLanguages?.[application]?.[0] ??
                    registryConfiguration?.defaultLanguage ??
                    defaultLanguage;
                if (
                    !isLanguageSwitchEnabled &&
                    this._preferredLanguage !== defaultRegistryLanguage
                ) {
                    this.switchCurrentLanguage(defaultRegistryLanguage);
                }
            }),
            map(([isLanguageSwitchEnabled]) => isLanguageSwitchEnabled),
        );
    }

    findCurrentPath(): string {
        const currentPath = this._location.path().split("?")[0];
        const savedPath = sessionStorage.getItem(this._savedPathKey);
        if (!currentPath && savedPath) {
            sessionStorage.removeItem(this._savedPathKey);
            return savedPath;
        }
        return currentPath;
    }

    addStrings(lang: string, strings: Dictionary<LocalizationJson>): void {
        if (!this.languages[lang]) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;
    }

    switchCurrentLanguage(lang: string): void {
        localStorage.setItem(this._preferredLanguageKey, lang);
        this.reloadPage();
    }

    reloadPage(): void {
        window.location.reload();
    }

    findRegistrySpecificLanguage(tenant: RegistryTenant): string | undefined {
        const registryInUrl = this._getRegistryFromUrl();
        if (registryInUrl) {
            const language = this.findDefaultLanguageForRegistry(registryInUrl);
            if (language) {
                return language;
            }
        }

        if (registryInUrl && REGISTRY_TENANTS_LOOKUP[tenant].includes(registryInUrl)) {
            const defaultRegistry = REGISTRY_TENANTS_LOOKUP[tenant][0];
            return this.findDefaultLanguageForRegistry(defaultRegistry);
        }

        return defaultLanguage;
    }

    observeDefaultLanguage(registrySource$: Observable<string>): void {
        if (localStorage.getItem(this._preferredLanguageKey) != null) {
            return;
        }
        registrySource$
            .pipe(
                filter(registry => ALL_REGISTRIES.includes(registry)),
                map(registry => this.findDefaultLanguageForRegistry(registry) ?? defaultLanguage),
                filter(
                    currentDefaultLanguage => currentDefaultLanguage !== this._preferredLanguage,
                ),
            )
            .subscribe(() => {
                this.reloadPage();
            });
    }

    findDefaultLanguageForRegistry(registry: string): string | undefined {
        return registryLocalizationConfiguration[registry]?.defaultLanguage;
    }

    private _saveCurrentPathToSessionStorage(): void {
        const path = this._location.path().split("?")[0];
        if (path) {
            sessionStorage.setItem(this._savedPathKey, path);
        }
    }

    private _getRegistryFromUrl(): string {
        const urlParams = this.findCurrentPath()
            .split("/")
            .filter(param => param.length > 0)
            .filter(param => param !== "portal");
        if (urlParams.length > 0) {
            return urlParams[0];
        }
        return "";
    }
}
