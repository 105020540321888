export interface Auth0User {
    email: string;
    email_verified: boolean;
    name: string;
    nickname: string;
    picture: string;
    given_name: string;
    family_name: string;
    sub: string;
    updated_at: string;
    locale: string;

    /**
     * @deprecated
     */
    "https://mrdm.io/applications"?: string[];
    /**
     * @deprecated
     */
    "https://mrdm.io/healthcareserviceproviders"?: string[];
    /**
     * @deprecated
     */
    "https://mrdm.io/position"?: string;
}
